@import "../../../assets/scss/variables";

.blDetailsInner {
    .thumb {
        img {
            width: 100%;
        }
    }
    .blogContent {
        padding-left: 100px;
        .author {
            display: flex;
            align-items: center;

            margin-top: 40px;
            margin-bottom: 30px;
            .authorInfo {
                padding-left: 15px;
                h6 {
                    font-size: 24px;
                    font-weight: 700;

                    margin: 0 0 4px;

                    color: #333;
                }
                span {
                    font-size: 14px;
                    font-weight: 500;

                    color: #333;
                }
            }
        }
        p {
            font-size: 16px;
            line-height: 33px;

            margin-bottom: 0;

            color: #333;
        }
        h2 {
            &.blTitle {
                font-size: 24px;
                font-weight: 500;

                margin-top: 45px;
                margin-bottom: 17px;

                color: #333;
            }
        }
        blockquote {
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;

            position: relative;

            margin: 61px 118px 40px;

            text-align: center;

            color: #333;
        }
    }
    .blList {
        display: flex;
        justify-content: space-between;

        margin: 50px -15px 0;
        padding: 0;

        list-style: outside none none;
        li {
            padding: 0 15px;

            img {
                width: 100%;
            }
        }
    }
}

@media #{$lg-layout} {
    .blDetailsInner .blogContent blockquote {
        margin: 61px 73px 40px;
    }
    .blDetailsInner .blogContent {
        padding-left: 70px;
    }
}

@media #{$md-layout} {
    .blDetailsInner .blogContent blockquote {
        margin: 61px 75px 40px;
    }
    .blDetailsInner .blogContent {
        padding-left: 70px;
    }
}

@media #{$sm-layout} {
    .blDetailsInner .blogContent {
        padding-left: 50px;
    }
    .blDetailsInner .blogContent blockquote {
        margin: 61px 9px 40px;
    }
    .blDetailsInner .blogContent p {
        font-size: 14px;
        line-height: 26px;
    }
}

@media #{$xs-layout} {
    .blDetailsInner .blogContent blockquote {
        margin: 61px 0 40px;
    }
    .blDetailsInner .blogContent blockquote::before {
        left: -64px;
    }
    .blDetailsInner .blogContent .author .authorInfo h6 {
        font-size: 21px;
    }
}

@media #{$xxs-layout} {
    .blDetailsInner .blogContent {
        padding-left: 0;
    }
    .blDetailsInner .blogContent blockquote {
        font-size: 16px;

        margin: 61px 25px 40px;
    }
    .blDetailsInner .blogContent blockquote::before {
        left: -41px;
    }
}
