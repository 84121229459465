@import "../../../assets/scss/variables";

.voopoVideo {
    padding-bottom: 200px;
}

.videoInner {
    position: relative;
    z-index: 2;
    &::before {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        content: "";

        opacity: 0.85;
        background: -webkit-gradient(
        linear,
        right top,
        left top,
        from(#1343A3),
        to(#011056)
        );
        background: linear-gradient(to left, #1343A3, #011056);
    }
    .videoContent {
        position: relative;
        top: 80px;

        overflow: hidden;

        margin-left: 80px;
        padding: 40px 34px 80px;

        border-radius: 10px;
        background: #FFFFFF none repeat scroll 0 0;
        .videoContentIcon {
            position: absolute;
            right: -94px;
            bottom: -150px;

            width: 234px;
            height: 199px;

            content: "";
            transform: rotate(220deg);

            background-repeat: no-repeat;
            background-position: right bottom;
        }
        p {
            font-size: 16px;
            line-height: 27px;

            margin: 0;
            & + p {
                margin-top: 27px;
            }
        }
        h2 {
            font-size: 36px;

            position: relative;

            margin-bottom: 33px;
            padding-bottom: 38px;

            text-align: center;

            color: #333;
            &::before {
                position: absolute;
                bottom: 0;
                left: 50%;

                width: 150px;
                height: 3px;

                content: "";
                transform: translateX(-50%);

                background: linear-gradient(to right, #E05A42, #FBCDC7);
            }
        }
    }
}

.videoTrigger {
    position: absolute;
    top: 50%;

    width: 100%;

    transform: translateY(-50%);
    text-align: center;
    button {
        font-size: 30px;
        line-height: 100px;

        position: relative;

        display: inline-block;

        width: 100px;
        height: 100px;

        border: none;
        border-radius: 100%;
        background: none;
        background: rgb(248, 9, 9) none repeat scroll 0 0;
        .iconWrapper {
            line-height: 66px;

            position: absolute;
            top: 50%;
            left: 50%;

            width: 66px;
            height: 66px;

            transition: 0.5s;
            transform: translate(-50%, -50%);
            text-align: center;

            border-radius: 100%;
            background: #FFAB2B none repeat scroll 0 0;
            svg {
                color: #FFF;
            }
        }
    }
}

@media #{$lg-layout} {
    .videoInner .videoContent {
        margin-left: 60px;
    }
}

@media #{$md-layout} {
    .videoInner .videoContent {
        margin-left: 29px;
    }
}

@media #{$sm-layout} {
    .videoInner .videoContent {
        margin-left: 0;
    }
    .videoTrigger {
        width: auto;
    }
    .videoTrigger a {
        line-height: 80px;

        width: 80px;
        height: 80px;
    }
    .voopoVideo {
        padding-bottom: 154px;
    }
}

@media #{$xs-layout} {
    .videoInner .videoContent {
        top: 20px;

        margin-right: 20px;
        margin-left: 20px;
    }
    .voopoVideo {
        padding-bottom: 139px;
    }
    .videoInner .videoContent h2 {
        font-size: 24px;

        margin-bottom: 23px;
        padding-bottom: 27px;
    }
    .videoInner .videoContent {
        padding: 40px 29px 80px;
    }
}
